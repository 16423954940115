<template>
  <Layout>
    <ol class="c-card">
      <li
        v-for="(item, index) in pageList"
        :key="item.path"
      >
        <router-link :to="item.path" target='_blank'>
          <span>{{index + 1}}、</span>
          <span>{{item.name}}</span>
        </router-link>
      </li>
    </ol>
  </Layout>
</template>

<script>
import {list} from '@/router'
export default {
  data() {
    return {}
  },
  computed: {
    pageList() {
      let res = []
      list.forEach((el) => {
        res.push({
          name: el.meta.title,
          path: el.path
        })
        if (el.children) {
          el.children.forEach(ele => {
            res.push({
              name: ele.meta.title,
              path: ele.path
            })
          })
        }
      })
      return res
    }
  }
};
</script>

<style lang="scss" scoped>
.c-card{
  width: 5rem;
  margin: 0.3rem auto;
  padding: 0.3rem;
  li{
    margin: 10px 0;
  }
  a{
    &:visited{
      color: #999;
    }
    &:hover{
      color: $blue;
    }
  }
}
</style>